import React from "react";
import { useTranslation } from "react-i18next";
import Banner from "../components/shared/banner";
import Services from "../components/iot/Services";

const IOT = () => {
  const { t } = useTranslation();

  return (
    <>
      <Banner
        head={t("iot.iot_banner.heading")}
        subHead2={t("iot.iot_banner.sub_heading")}
        textAlign="text-center"
        desktopvid="https://qazibucket-dest.s3.us-east-2.amazonaws.com/octaloop/aboutus.mp4"
        mobvid="https://qazibucket-dest.s3.us-east-2.amazonaws.com/octaloop/aboutus.mp4"
        poster="/assets/images/quantum_poster.webp"
      />
      <Services />

    </>
  );
};

export default IOT;
