import React, { useEffect, useState } from "react";
import "./navbar.scss";
import { Link, NavLink, useLocation, useNavigate } from "react-router-dom";
import { Icon } from "@iconify/react";
import { Drawer } from "antd";

import LanguageSwitcher from "../../LanguageSwitch";
import { useTranslation } from "react-i18next";

function Navbar() {
  const navigate = useNavigate();
  const location = useLocation();

  const { t } = useTranslation();

  const [open, setOpen] = useState(false);
  const [customLogo, setCustomLogo] = useState(false);
  const [placement, setPlacement] = useState("left");
  const handleMenu = () => {
    setOpen(!open);
  };
  const shouldCustomNav = ["/contact-us"].includes(location.pathname);
  useEffect(() => {
    if (shouldCustomNav) {
      setCustomLogo(true);
    } else {
      setCustomLogo(false);
    }
  });

  return (
    <>
      <div className="navbar-sec d-lg-block d-none ">
        <nav className="navbar navbar-expand-lg ">
          <Link className="navbar-brand" to="/">
            {customLogo ? (
              <img src="/assets/icons/logo.svg" alt="" />
            ) : (
              <img src="/assets/icons/logowhite.svg" alt="" />
            )}
          </Link>
          <button
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            {/* <Hamburger toggled={isOpen} toggle={setOpen} /> */}
          </button>

          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav mx-auto">
              <li className="nav-item">
                <NavLink className="nav-link" to="/">
                  {t("navbar.home")}
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink className="nav-link" to="/about-us">
                  {t("navbar.aboutUs")}
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink className="nav-link" to="/services">
                  {t("navbar.services")}
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink className="nav-link" to="/portfolio">
                  {t("navbar.portfolio")}
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink className="nav-link text-uppercase " to="/quantum">
                  {t("navbar.quantum")}
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink className="nav-link text-uppercase " to="/iot">
                  {t("navbar.iot")}
                </NavLink>
              </li>
              <li className="nav-item">
                <LanguageSwitcher />
              </li>
            </ul>
            <div className="nav-btn">
              <button type="submit" onClick={() => navigate("/contact-us")}>
                {t("contacter.contactUs")}
              </button>
            </div>
          </div>
        </nav>
      </div>

      <nav className="bg-black  mb-nav pt-3 d-lg-none d-block">
        <header className="  v-center justify-content-between p-3">
          <Link className="navbar-brand" to="/">
            <img
              src="/assets/icons/logowhite.svg"
              alt=""
              className="invert-1 "
            />
          </Link>
          {open ? (
            <Icon
              icon="flowbite:close-solid"
              width="30"
              height="30"
              style={{ color: "#fff" }}
              onClick={handleMenu}
            />
          ) : (
            <Icon
              // icon="flowbite:close-solid"
              icon="mingcute:menu-line"
              width="30"
              height="30"
              style={{ color: "#fff" }}
              onClick={handleMenu}
            />
          )}
        </header>
      </nav>

      <Drawer
        placement={placement}
        closable={false}
        onClose={handleMenu}
        open={open}
        key={placement}
        className="p-0"
      >
        <div className=" mb-nav pt-3">
          <header className="  v-center justify-content-between p-3 bg-black p-0">
            <Link className="navbar-brand" to="/">
              <img
                src="/assets/icons/logowhite.svg"
                alt=""
                className="invert-1 "
              />
            </Link>
            {open ? (
              <Icon
                icon="flowbite:close-solid"
                width="42"
                height="42"
                style={{ color: "#fff" }}
                onClick={handleMenu}
              />
            ) : (
              <Icon
                icon="flowbite:close-solid"
                width="42"
                height="42"
                style={{ color: "#fff" }}
                onClick={handleMenu}
              />
            )}
          </header>

          <ul className="navbar-nav mx-auto p-3">
            <li className="nav-item">
              <NavLink className="nav-link" to="/" onClick={handleMenu}>
                {t("navbar.home")}
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink className="nav-link" to="/about-us" onClick={handleMenu}>
                {t("navbar.aboutUs")}
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink className="nav-link" to="/services" onClick={handleMenu}>
                {t("navbar.services")}
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                className="nav-link"
                to="/portfolio"
                onClick={handleMenu}
              >
                {t("navbar.portfolio")}
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                className="nav-link text-uppercase "
                to="/quantum"
                onClick={handleMenu}
              >
                {t("navbar.quantum")}
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                className="nav-link text-uppercase "
                to="/iot"
                onClick={handleMenu}
              >
                {t("navbar.iot")}
              </NavLink>
            </li>
            <li className="nav-item">
              <LanguageSwitcher />
            </li>
            <div className="nav-btn">
              <button
                type="submit"
                onClick={() => {
                  navigate("/contact-us");
                  handleMenu();
                }}
              >
                {t("contacter.contactUs")}
              </button>
            </div>
          </ul>
        </div>
      </Drawer>
    </>
  );
}

export default Navbar;
