import React from "react";
import Animatedheading from "../../animatedheading/animatedheading";
import { useTranslation } from "react-i18next";
import "./secureSolutions.scss";
function SecureSolutions({ outer, innerfirst, innersecond, para }) {
  const { t } = useTranslation();

  console.log(outer);
  const cardInfo = [
    {
      heading1: t("secure.secure_key1"),
      heading2: t("secure.secure_key2"),
      para: t("secure.secure_key_description"),
      src: "/assets/images/securecards/1.webp",
    },
    {
      heading1: t("secure.secure_ip1"),
      heading2: t("secure.secure_ip2"),
      para: t("secure.secure_ip_description"),
      src: "/assets/images/securecards/2.webp",
    },
    {
      heading1: t("secure.latice1"),
      heading2: t("secure.latice2"),
      para: t("secure.latice_description"),
      src: "/assets/images/securecards/3.webp",
    },
    {
      heading1: t("secure.entropy1"),
      heading2: t("secure.entropy2"),
      para: t("secure.entropy_description"),
      src: "/assets/images/securecards/4.webp",
    },
  ];
  return (
    <>
      <section className="secureSolutions-sec">
        <div className="heading">
          <Animatedheading
            outer={outer}
            innerfirst={innerfirst}
            innersecond={innersecond}
          />
          <p className="detail-para">{para}</p>
        </div>
        <div className="secureSolutions-cards">
          {cardInfo.map((items, index) => (
            <div className="secure-card">
              <div className="outer">
                <div className="img-div">
                  <img src={items.src} alt="..." />
                </div>
                <div className="secure-card-content">
                  <label className="xlarge-para text-white ">
                    {items.heading1} <br />
                    {items.heading2}
                  </label>
                  <p className="large-paraDeci text-white text-start">
                    {items.para}
                  </p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </section>
    </>
  );
}

export default SecureSolutions;
