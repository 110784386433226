import React, { useState } from "react";
import "./footer.scss";
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Modal } from "antd";
function Footer() {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  return (
    <section className="footer-sec">
      <div className="left">
        <img
          src="/assets/icons/logowhite.svg"
          alt="..."
          className="footer-logo"
          onClick={() => navigate("/")}
          style={{ cursor: "pointer" }}
        />
        <span>{t("footers.welcomeText")}</span>
        <div className="location">
          <div className="address">
            <img src="/assets/icons/dubai.png" alt="..." />
            <div className="country">
              <span className="bold-span">{t("footers.location1")}</span>
              <span className="light-span">{t("footers.address1")}</span>
            </div>
          </div>
          <div className="address">
            <img src="/assets/icons/pakistan.png" alt="..." />
            <div className="country">
              <span className="bold-span">{t("footers.location2")}</span>
              <span className="light-span">{t("footers.address2")}</span>
            </div>
          </div>
          <div className="address">
            <img src="/assets/icons/pakistan.png" alt="..." />
            <div className="country">
              <span className="bold-span">{t("footers.location3")}</span>
              <span className="light-span">{t("footers.address3")}</span>
            </div>
          </div>
          <div className="address">
            <img src="/assets/icons/France.svg" alt="..." />
            <div className="country">
              <span className="bold-span">{t("footers.location4")}</span>
              <span className="light-span">{t("footers.address4")}</span>
            </div>
          </div>

          {/* Germany */}
          <div className="address">
            <img src="/assets/icons/Germany.svg" alt="..." />
            <div className="country">
              <span className="bold-span">{t("footers.location5")}</span>
              <span className="light-span">{t("footers.address5")}</span>
            </div>
          </div>

          {/* UK */}
          <div className="address">
            <img src="/assets/icons/united-kingdom.png" alt="..." />
            <div className="country">
              <span className="bold-span">{t("footers.location6")}</span>
              <span className="light-span">{t("footers.address6")}</span>
            </div>
          </div>
        </div>
      </div>
      <div className="center">
        <a href=" https://www.instagram.com/octaloop.io/" target="_blank">
          <img src="/assets/icons/footer/insta.svg" alt="..." />
          <p className="large-para">{t("footers.insta")}</p>
        </a>
        <a href="https://www.facebook.com/octalooptech/" target="_blank">
          <img src="/assets/icons/footer/facebook.svg" alt="..." />
          <p className="large-para">{t("footers.Facebook")}</p>
        </a>
        {/* <a href="#">
          <img src="/assets/icons/footer/google.svg" alt="..." />
          <p className="large-para">Google</p>
        </a> */}
        <a href="https://twitter.com/OctaloopTech" target="_blank">
          <img src="/assets/icons/footer/twitter.svg" alt="..." />
          <p className="large-para">{t("footers.Twitter")}</p>
        </a>
        <a
          href="https://www.linkedin.com/company/octaloop-technologies"
          target="_blank"
        >
          <img src="/assets/icons/footer/linkedin.svg" alt="..." />
          <p className="large-para">{t("footers.LinkedIn")}</p>
        </a>
        <a href="https://medium.com/@octaloop.tech" target="_blank">
          <img src="/assets/icons/footer/medium.svg" alt="..." />
          <p className="large-para">{t("footers.Medium")}</p>
        </a>
      </div>
      <div className="right">
        <h3>
          {t("footers.getQuote")} <span>{t("footers.quote")}</span>
        </h3>
        <label>{t("footers.bookConsultation")} </label>
        <div className="pages-links">
          <a href="/">{t("footers.terms")}</a>
          <a href="/">{t("footers.policy")}</a>
          <Link to="/contact-us">{t("footers.careero")} </Link>
          <a onClick={showModal}>Impressum</a>
        </div>
      </div>

      <Modal
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        centered
        footer={null}
      >
        <div className="impressum">
          <p className="text-center mb-2">
            <b>Impressum</b>
          </p>
          <p className="small-para mt-3">
            Octaloop Information Technology Netwrok Services Co. LLC
          </p>
          <br />
          <p className="small-para">
            <strong>{t("impressum.license")} :</strong> 1185842
          </p>

          <br />
          <p className="small-para">
            <strong>{t("impressum.legal")}:</strong> Limited Liability Company
          </p>
          <br />
          <p className="small-para">
            <strong>{t("impressum.reg")}: </strong> 1978877
          </p>

          <p className="small-para mb-2 mt-3">
            <b>Managing Directors:</b>
          </p>
          <ul>
            <li>
              <strong>Aamir Ahmed</strong> - CEO
            </li>
            <li>
              <strong>Wasaam Qazi</strong> - MD/CTO
            </li>
            <li>
              <strong>Murray</strong> - Co-Founder
            </li>
          </ul>
          <p className="small-para mb-2 mt-3">
            <strong>{t("impressum.bAddress")}:</strong>
          </p>
          <p className="small-para mb-2 ">
            {t("impressum.address")}
            <br />
          </p>

          <p className="small-para mb-2 mt-3">
            <strong>{t("impressum.contactInfo")}</strong>
          </p>
          <p className="small-para mb-2 mt-3">
            {t("impressum.phone")}:{" "}
            <a href="tel:+493012345678">+971 56 365 4106</a>
            <br />
            E-Mail: <a href="mailto:info@ocatloop.de">business@ocatloop.io</a>
          </p>
        </div>
      </Modal>
    </section>
  );
}

export default Footer;
