import React from "react";
import { useTranslation } from "react-i18next";


import './about.scss';


const About = () => {
  const { t } = useTranslation();

  const about_typo = {
    headingA: t("quantum_about.heading1"),
    headingB: t("quantum_about.heading2"),
    dsc: t("quantum_about.dsc")
  }
  return (
    <>
      <section className="about-sec">
        <div className="content">
          {/* Left content column */}
          <div className="left ">
            <h2 className="">
              { about_typo.headingA } <br />
              {about_typo.headingB}
            </h2>
            <p>
              {about_typo.dsc}
            </p>
          </div>

          {/* Right content column */}
          <div className="right">
            <img
              src="\assets\images\about_quantum.webp"
              alt="Quantum Computing"
              className="" // Ensures the image is responsive
            />
          </div>
        </div>
      </section>
    </>
  );
};

export default About;
