import React, { useState, useEffect, useRef } from "react";
import AI from "../../../assets/service-cards/AI.webp";
import web from "../../../assets/service-cards/web.webp";
import mobile from "../../../assets/service-cards/mobile-app.webp";
import quantum from "../../../assets/service-cards/quantum.webp";
import ui from "../../../assets/service-cards/ui.webp";
// import design from "../../../assets/service-cards/2d.webp";
import game from "../../../assets/service-cards/game-dev.webp";
import sales from "../../../assets/service-cards/sales.webp";
import quality from "../../../assets/service-cards/quality.webp";
import arrow from "../../../assets/service-cards/arrow.webp";
import arrow2 from "../../../assets/service-cards/arrow2.webp";
import Animatedheading from "../../animatedheading/animatedheading";
import { useTranslation } from "react-i18next";

const ServiceCards = () => {
  const [show, setShow] = useState(false);
  const { t } = useTranslation();
  const serviceCardInfo = [
    {
      back: AI,
      number: t("serviceCardinfo.number1"),
      serviceist: t("serviceCardinfo.serviceist1"),
      servicesecond: t("serviceCardinfo.servicesecond1"),
      detail: t("serviceCardinfo.detail1"),
    },
    {
      back: quantum,
      number: t("serviceCardinfo.number2"),
      serviceist: t("serviceCardinfo.serviceist2"),
      servicesecond: t("serviceCardinfo.servicesecond2"),
      detail: t("serviceCardinfo.detail2"),
    },
    {
      back: web,
      number: t("serviceCardinfo.number3"),
      serviceist: t("serviceCardinfo.serviceist3"),
      servicesecond: t("serviceCardinfo.servicesecond3"),
      detail: t("serviceCardinfo.detail3"),
    },
    {
      back: ui,
      number: t("serviceCardinfo.number4"),
      serviceist: t("serviceCardinfo.serviceist4"),
      servicesecond: t("serviceCardinfo.servicesecond4"),
      detail: t("serviceCardinfo.detail4"),
    },
    {
      back: mobile,
      number: t("serviceCardinfo.number5"),
      serviceist: t("serviceCardinfo.serviceist5"),
      servicesecond: t("serviceCardinfo.servicesecond5"),
      detail: t("serviceCardinfo.detail5"),
    },
    // {
    //   back: design,
    //   number: t("serviceCardinfo.number5"),
    //   serviceist: t("serviceCardinfo.serviceist5"),
    //   servicesecond: t("serviceCardinfo.servicesecond5"),
    //   detail: t("serviceCardinfo.detail5"),
    // },
    {
      back: game,
      number: t("serviceCardinfo.number6"),
      serviceist: t("serviceCardinfo.serviceist6"),
      servicesecond: t("serviceCardinfo.servicesecond6"),
      detail: t("serviceCardinfo.detail6"),
    },
    {
      back: sales,
      number: t("serviceCardinfo.number7"),
      serviceist: t("serviceCardinfo.serviceist7"),
      servicesecond: t("serviceCardinfo.servicesecond7"),
      detail: t("serviceCardinfo.detail7"),
    },
    {
      back: quality,
      number: t("serviceCardinfo.number8"),
      serviceist: t("serviceCardinfo.serviceist8"),
      servicesecond: t("serviceCardinfo.servicesecond8"),
      detail: t("serviceCardinfo.detail8"),
    },
  ];
  const handleMessage = (index) => {
    if (show === index) {
      setShow(-1); // If the same card is clicked again, hide details
    } else {
      setShow(index); // Show details of the clicked card
    }
  };

  return (
    <div className="homeservices-sec">
      <Animatedheading
        outer={t("home_services.innovations")}
        innerfirst={t("home_services.ser")}
        innersecond={t("home_services.vices")}
      />
      <div className="service-cards">
        {serviceCardInfo.map((items, index) => (
          <div className="content" key={index}>
            <div className="back-image">
              <img src={items.back} alt="img" className="img-fluid" />
            </div>
            <div className="text-content">
              <h2>{items.number}</h2>
              <div>
                <h4>{items.serviceist}</h4>
                <h4>{items.servicesecond}</h4>
              </div>
            </div>
            <p className="para">{items.detail}</p>
            <div className="arrow pointer">
              <img src={arrow} alt="" />
            </div>
            <div
              className="arrow2 pointer"
              onClick={() => handleMessage(index)}
            >
              <img src={arrow2} alt="" />
            </div>
            <div className="black-layer"></div>
            <img
              src="/assets/images/soon.webp"
              alt="..."
              className={`soon-img   ${
                show === index ? "show-img" : "hide-img"
              }`}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default ServiceCards;
