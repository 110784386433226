import React from 'react';
import './heading.scss';

const QuantumHeadin = (props) => {
  return (
    <div className='heading-sec'>
      <h2 className='outer-head'> {props.outer} </h2>
      <h4 className='inner-head'> {props.inner} </h4>
      <p className='para'> {props.para} </p>
    </div>
  )
}

export default QuantumHeadin
