import React from "react";
import "./partner.scss";
import QuantumHeading from "../quantumHeading";

import { useTranslation } from "react-i18next";

const Partner = () => {
  const { t } = useTranslation();
  const para = t("mapPara.desc");
  return (
    <div className="partner-sec">
      <div className="heading-sec">
        <QuantumHeading 
          outer = { t("quantum_partner.outer")} 
          inner ={ t("quantum_partner.inner")} 
        />
      </div>
      <div className="inner">
        <div className="content">
          <div className="frame">
            <img src="./assets/images/quantum/mapframe.png" className="framImg" />
          </div>
          <img
            src="./assets/images/quantum/infinity.png"
            className="infinity"
          />
          <p className="para">{para}</p>
        </div>
      </div>
    </div>
  );
};

export default Partner;
