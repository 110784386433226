import React, { useContext, useState, useRef, useEffect } from "react";
import { useTranslation } from "react-i18next";
import "./switcher.css";

// Import flag SVGs
import enFlag from "../src/assets/icons/united kingdom.svg";
import frFlag from "../src/assets/icons/france.svg";
import deFlag from "../src/assets/icons/germany.svg";
import arFlag from "../src/assets/icons/saudi arabia.svg";
import krFlag from "../src/assets/icons/korea.svg";
import { LanguageContext } from "./context/language";

const LanguageSwitcher = () => {
  const { i18n } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const currentLanguage = i18n.language;
  const { language, updateLanguage } = useContext(LanguageContext);
  const dropdownRef = useRef(null); // Create a reference to the dropdown

  const changeLanguage = (lang) => {
    i18n.changeLanguage(lang);
    updateLanguage(lang);
    setIsOpen(false);
  };

  const toggleDropdown = () => setIsOpen(!isOpen);

  // Close dropdown if click is outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className="language-switcher" ref={dropdownRef}>
      <button className="dropdowns-toggle" onClick={toggleDropdown}>
        {/* Display the current language flag */}
        <img
          src={
            currentLanguage === "en"
              ? enFlag
              : currentLanguage === "fr"
              ? frFlag
              : currentLanguage === "kr"
              ? krFlag
              : currentLanguage === "de"
              ? deFlag
              : currentLanguage === "nl" 
              ? deFlag
              : currentLanguage === "ar"
              ? arFlag
              : enFlag
          }
          alt={currentLanguage}
          className="flag-icon me-2"
        />
        <svg
          className="dropo"
          xmlns="http://www.w3.org/2000/svg"
          width="1em"
          height="1em"
          viewBox="0 0 12 12"
        >
          <path
            fill="currentColor"
            d="M6 8.825c-.2 0-.4-.1-.5-.2l-3.3-3.3c-.3-.3-.3-.8 0-1.1s.8-.3 1.1 0l2.7 2.7l2.7-2.7c.3-.3.8-.3 1.1 0s.3.8 0 1.1l-3.2 3.2q-.3.3-.6.3"
          ></path>
        </svg>{" "}
      </button>
      {isOpen && (
        <div className="dropdown-menu">
          <div onClick={() => changeLanguage("en")} className="dropdown-item">
            <img src={enFlag} alt="English" className="flag-icon me-2" />
            English
          </div>
          <div onClick={() => changeLanguage("fr")} className="dropdown-item">
            <img src={frFlag} alt="Français" className="flag-icon me-2" />
            Français
          </div>
          <div onClick={() => changeLanguage("nl")} className="dropdown-item">
            <img src={deFlag} alt="Deutsch" className="flag-icon me-2" />
            Deutsch
          </div>
          <div onClick={() => changeLanguage("ar")} className="dropdown-item">
            <img src={arFlag} alt="العربية" className="flag-icon me-2" />
            العربية
          </div>
          <div onClick={() => changeLanguage("kr")} className="dropdown-item">
            <img src={krFlag} alt="한국인" className="flag-icon me-2" />
            한국인
          </div>
          <div onClick={() => changeLanguage("de")} className="dropdown-item">
            <img src={deFlag} alt="german" className="flag-icon me-2" />
            German
          </div>
        </div>
      )}
    </div>
  );
};

export default LanguageSwitcher;
