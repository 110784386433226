import React from "react";
import { useTranslation } from "react-i18next";
import Animatedheading from "../animatedheading/animatedheading";
import "./iot_services.scss";

// Images
import health from "../../assets/iot/img/iot_health.png";
import aggriculture from "../../assets/iot/img/iot_agriculture.png";
import smartCity from "../../assets/iot/img/iot_smartCity.png";
import energy from "../../assets/iot/img/iot_enrgy.png";
import retail from "../../assets/iot/img/iot_retails.png";
import homeAutomation from "../../assets/iot/img/iot_home_automation.png";
import vehicle from "../../assets/iot/img/iot_car_automation.png";
import security from "../../assets/iot/img/iot_security.png";

// counting imgs
import step1 from "../../assets/iot/img/step_1.png";
import step2 from "../../assets/iot/img/step_2.png";
import step3 from "../../assets/iot/img/step_3.png";
import step4 from "../../assets/iot/img/step_4.png";
import step5 from "../../assets/iot/img/step_5.png";
import step6 from "../../assets/iot/img/step_6.png";
import step7 from "../../assets/iot/img/step_7.png";
import step8 from "../../assets/iot/img/step_8.png";

import check from "../../assets/iot/img/check.png";
import left from "../../assets/iot/img/bg_color_left.png";
import right from "../../assets/iot/img/bg_color_right.png";

const Services = () => {
  const { t } = useTranslation();

  return (
    <div className="iot_services-sec  ">
      <div className="iot-services">
        <Animatedheading
          outer={t("iot.animate_heading.animate_outer")}
          innerfirst={t("iot.animate_heading.animate_inner_first")}
          innersecond={t("iot.animate_heading.animate_inner_second")}
        />
          <div className="services">
            <div className="service-1">
              <div className="left">
                <div className="heading">
                  <h1 className="heading1 ">
                    {t("iot.services.service_1.heading_part1")}
                    <span className=" heading2 ">
                      {t("iot.services.service_3.heading_part2")}
                    </span>
                  </h1>
                </div>
                <div className="desc ">
                  <img src={step1} className="step-img " alt="step1" />
                  <div className="">
                    <p className="para">
                      {t("iot.services.service_1.desc")}
                      {}
                    </p>
                    <div className="benefits">
                      <div className="benefit">
                        <img src={check} alt="checkImg" className="checkImg" />
                        <p className="benefit-text">
                          {t("iot.services.service_1.benefits.benefit_1")}
                        </p>
                      </div>
                      <div className="benefit">
                        <img src={check} alt="checkImg" className="checkImg" />
                        <p className="benefit-text">
                          {t("iot.services.service_1.benefits.benefit_2")}
                        </p>
                      </div>
                      <div className="benefit">
                        <img src={check} alt="checkImg" className="checkImg" />
                        <p className="benefit-text">
                          {t("iot.services.service_1.benefits.benefit_3")}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="right">
                <div className="img-outer">
                  <img
                    src={health}
                    className="inner-img "
                  />
                  <img
                    src="./assets/images/feverback.webp"
                    className="fever-back"
                  />
                </div>
              </div>
            </div>
            <div className="service-2">
            <div className="right">
                <div className="img-outer">
                  <img
                    src={aggriculture}
                    className="inner-img "
                  />
                  <img
                    src="./assets/images/feverback.webp"
                    className="fever-back"
                  />
                </div>
              </div>
              <div className="left">
                <div className="heading">
                  <h1 className="heading1 ">
                    {t("iot.services.service_2.heading_part1")}
                    <span className=" heading2 ">
                      {t("iot.services.service_2.heading_part2")}
                    </span>
                  </h1>
                </div>
                <div className="desc ">
                  <img src={step2} className="step-img " alt="step1" />
                  <div className="">
                    <p className="para">
                      {t("iot.services.service_2.desc")}
                      {}
                    </p>
                    <div className="benefits">
                      <div className="benefit">
                        <img src={check} alt="checkImg" className="checkImg" />
                        <p className="benefit-text">
                          {t("iot.services.service_2.benefits.benefit_1")}
                        </p>
                      </div>
                      <div className="benefit">
                        <img src={check} alt="checkImg" className="checkImg" />
                        <p className="benefit-text">
                          {t("iot.services.service_2.benefits.benefit_2")}
                        </p>
                      </div>
                      <div className="benefit">
                        <img src={check} alt="checkImg" className="checkImg" />
                        <p className="benefit-text">
                          {t("iot.services.service_2.benefits.benefit_3")}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

            </div>
            <div className="service-3">
              <div className="left">
                <div className="heading">
                  <h1 className="heading1 ">
                    {t("iot.services.service_3.heading_part1")}
                    <span className=" heading2 ">
                      {t("iot.services.service_3.heading_part2")}
                    </span>
                  </h1>
                </div>
                <div className="desc ">
                  <img src={step3} className="step-img " alt="step1" />
                  <div className="">
                    <p className="para">
                      {t("iot.services.service_3.desc")}
                      {}
                    </p>
                    <div className="benefits">
                      <div className="benefit">
                        <img src={check} alt="checkImg" className="checkImg" />
                        <p className="benefit-text">
                          {t("iot.services.service_3.benefits.benefit_1")}
                        </p>
                      </div>
                      <div className="benefit">
                        <img src={check} alt="checkImg" className="checkImg" />
                        <p className="benefit-text">
                          {t("iot.services.service_3.benefits.benefit_2")}
                        </p>
                      </div>
                      <div className="benefit">
                        <img src={check} alt="checkImg" className="checkImg" />
                        <p className="benefit-text">
                          {t("iot.services.service_3.benefits.benefit_3")}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="right">
                <div className="img-outer">
                  <img
                    src={smartCity}
                    className="inner-img "
                  />
                  <img
                    src="./assets/images/feverback.webp"
                    className="fever-back"
                  />
                </div>
              </div>
            </div>
            <div className="service-4">
            <div className="right">
                <div className="img-outer">
                  <img
                    src={energy}
                    className="inner-img "
                  />
                  <img
                    src="./assets/images/feverback.webp"
                    className="fever-back"
                  />
                </div>
              </div>
              <div className="left">
                <div className="heading">
                  <h1 className="heading1 ">
                    {t("iot.services.service_4.heading_part1")}
                    <span className=" heading2 ">
                      {t("iot.services.service_4.heading_part2")}
                    </span>
                  </h1>
                </div>
                <div className="desc ">
                  <img src={step4} className="step-img " alt="step1" />
                  <div className="">
                    <p className="para">
                      {t("iot.services.service_4.desc")}
                      {}
                    </p>
                    <div className="benefits">
                      <div className="benefit">
                        <img src={check} alt="checkImg" className="checkImg" />
                        <p className="benefit-text">
                          {t("iot.services.service_4.benefits.benefit_1")}
                        </p>
                      </div>
                      <div className="benefit">
                        <img src={check} alt="checkImg" className="checkImg" />
                        <p className="benefit-text">
                          {t("iot.services.service_4.benefits.benefit_2")}
                        </p>
                      </div>
                      <div className="benefit">
                        <img src={check} alt="checkImg" className="checkImg" />
                        <p className="benefit-text">
                          {t("iot.services.service_4.benefits.benefit_3")}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

            </div>
            <div className="service-5">
              <div className="left">
                <div className="heading">
                  <h1 className="heading1 ">
                    {t("iot.services.service_5.heading_part1")}
                    <span className=" heading2 ">
                      {t("iot.services.service_5.heading_part2")}
                    </span>
                  </h1>
                </div>
                <div className="desc ">
                  <img src={step5} className="step-img " alt="step5" />
                  <div className="">
                    <p className="para">
                      {t("iot.services.service_5.desc")}
                      {}
                    </p>
                    <div className="benefits">
                      <div className="benefit">
                        <img src={check} alt="checkImg" className="checkImg" />
                        <p className="benefit-text">
                          {t("iot.services.service_5.benefits.benefit_1")}
                        </p>
                      </div>
                      <div className="benefit">
                        <img src={check} alt="checkImg" className="checkImg" />
                        <p className="benefit-text">
                          {t("iot.services.service_5.benefits.benefit_2")}
                        </p>
                      </div>
                      <div className="benefit">
                        <img src={check} alt="checkImg" className="checkImg" />
                        <p className="benefit-text">
                          {t("iot.services.service_5.benefits.benefit_3")}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="right">
                <div className="img-outer">
                  <img
                    src={retail}
                    className="inner-img "
                  />
                  <img
                    src="./assets/images/feverback.webp"
                    className="fever-back"
                  />
                </div>
              </div>
            </div>
            <div className="service-6">
            <div className="right">
                <div className="img-outer">
                  <img
                    src={homeAutomation}
                    className="inner-img "
                  />
                  <img
                    src="./assets/images/feverback.webp"
                    className="fever-back"
                  />
                </div>
              </div>
              <div className="left">
                <div className="heading">
                  <h1 className="heading1 ">
                    {t("iot.services.service_6.heading_part1")}
                    <span className=" heading2 ">
                      {t("iot.services.service_6.heading_part2")}
                    </span>
                  </h1>
                </div>
                <div className="desc ">
                  <img src={step6} className="step-img " alt="step1" />
                  <div className="">
                    <p className="para">
                      {t("iot.services.service_6.desc")}
                      {}
                    </p>
                    <div className="benefits">
                      <div className="benefit">
                        <img src={check} alt="checkImg" className="checkImg" />
                        <p className="benefit-text">
                          {t("iot.services.service_6.benefits.benefit_1")}
                        </p>
                      </div>
                      <div className="benefit">
                        <img src={check} alt="checkImg" className="checkImg" />
                        <p className="benefit-text">
                          {t("iot.services.service_6.benefits.benefit_2")}
                        </p>
                      </div>
                      <div className="benefit">
                        <img src={check} alt="checkImg" className="checkImg" />
                        <p className="benefit-text">
                          {t("iot.services.service_6.benefits.benefit_3")}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* <img src={left} alt="left_bg_color" className="bg-left" /> */}
            </div>
            <div className="service-7">
              <div className="left">
                <div className="heading">
                  <h1 className="heading1 ">
                    {t("iot.services.service_7.heading_part1")}
                    <span className=" heading2 ">
                      {t("iot.services.service_7.heading_part2")}
                    </span>
                  </h1>
                </div>
                <div className="desc ">
                  <img src={step7} className="step-img " alt="step1" />
                  <div className="">
                    <p className="para">
                      {t("iot.services.service_7.desc")}
                      {}
                    </p>
                    <div className="benefits">
                      <div className="benefit">
                        <img src={check} alt="checkImg" className="checkImg" />
                        <p className="benefit-text">
                          {t("iot.services.service_7.benefits.benefit_1")}
                        </p>
                      </div>
                      <div className="benefit">
                        <img src={check} alt="checkImg" className="checkImg" />
                        <p className="benefit-text">
                          {t("iot.services.service_7.benefits.benefit_2")}
                        </p>
                      </div>
                      <div className="benefit">
                        <img src={check} alt="checkImg" className="checkImg" />
                        <p className="benefit-text">
                          {t("iot.services.service_7.benefits.benefit_3")}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="right">
                <div className="img-outer">
                  <img
                    src={vehicle}
                    className="inner-img "
                  />
                  <img
                    src="./assets/images/feverback.webp"
                    className="fever-back"
                  />
                </div>
              </div>
            </div>
            <div className="service-8">
            <div className="right">
                <div className="img-outer">
                  <img
                    src={security}
                    className="inner-img "
                  />
                  <img
                    src="./assets/images/feverback.webp"
                    className="fever-back"
                  />
                </div>
              </div>
              <div className="left">
                <div className="heading">
                  <h1 className="heading1 ">
                    {t("iot.services.service_8.heading_part1")}
                    <span className=" heading2 ">
                      {t("iot.services.service_8.heading_part2")}
                    </span>
                  </h1>
                </div>
                <div className="desc ">
                  <img src={step8} className="step-img " alt="step1" />
                  <div className="">
                    <p className="para">
                      {t("iot.services.service_8.desc")}
                      {}
                    </p>
                    <div className="benefits">
                      <div className="benefit">
                        <img src={check} alt="checkImg" className="checkImg" />
                        <p className="benefit-text">
                          {t("iot.services.service_8.benefits.benefit_1")}
                        </p>
                      </div>
                      <div className="benefit">
                        <img src={check} alt="checkImg" className="checkImg" />
                        <p className="benefit-text">
                          {t("iot.services.service_8.benefits.benefit_2")}
                        </p>
                      </div>
                      <div className="benefit">
                        <img src={check} alt="checkImg" className="checkImg" />
                        <p className="benefit-text">
                          {t("iot.services.service_8.benefits.benefit_3")}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* <img src={right} alt="left_bg_color" className="bg-right" /> */}

            </div>
          </div>
      </div>
      <img src={left} alt="left_bg_color" className="bg-left1 " />
      <img src={left} alt="left_bg_color" className="bg-left2 " />
      <img src={right} alt="left_bg_color" className="bg-right1" />
      <img src={right} alt="left_bg_color" className="bg-right2" />


    </div>
  );
};

export default Services;
