import React from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";



import  './iot.scss';

//Icons
import health from "../../assets/iot/icons/iot_health.png";
import aggriculture from "../../assets/iot/icons/iot_aggriculture.png";
import smartCities from "../../assets/iot/icons/iot_smartCities.png";
import enertySustainable from "../../assets/iot/icons/iot_enery.png";
import retail from "../../assets/iot/icons/iot_retail.png";
import homeAutomation from "../../assets/iot/icons/iot_homeAutomation.png";
import vehicle from "../../assets/iot/icons/iot_vehicle.png";
import security from "../../assets/iot/icons/iot_security.png";
import iconsBG from "../../assets/iot/icons/icons_bg.png";
import Animatedheading from "../animatedheading/animatedheading";

const IotServices = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const serviceCard = [
    {
      icons: health,
      heading_t: t("iot_services.health_t"),
      heading_b: t("iot_services.health_b"),
      desc: t("iot_services.health_desc"),
    },
    {
      icons: aggriculture,
      heading_t: t("iot_services.aggriculture_t"),
      heading_b: t("iot_services.aggriculture_b"),
      desc: t("iot_services.aggriculture_desc"),

    },
    {
      icons: smartCities,
      heading_t: t("iot_services.smart_cities_t"),
      heading_b: t("iot_services.smart_cities_b"),
      desc: t("iot_services.smart_cities_desc"),
    },
    {
      icons: enertySustainable,
      heading_t: t("iot_services.energy_sustain_t"),
      heading_b: t("iot_services.energy_sustain_b"),
      desc: t("iot_services.energy_sustain_desc"),
    },
    {
      icons: retail,
      heading_t: t("iot_services.retail_logistics_t"),
      heading_b: t("iot_services.retail_logistics_b"),
      desc: t("iot_services.retail_logistics_desc"),
    },
    {
      icons: homeAutomation,
      heading_t: t("iot_services.home_automation_t"),
      heading_b: t("iot_services.home_automation_b"),
      desc: t("iot_services.home_automation_desc"),
    },
    {
      icons: vehicle,
      heading_t: t("iot_services.autonomous_vehicle_t"),
      heading_b: t("iot_services.autonomous_vehicle_b"),
      desc: t("iot_services.autonomous_vehicle_desc"),
    },
    {
      icons: security,
      heading_t: t("iot_services.security_t"),
      heading_b: t("iot_services.security_b"),
      desc: t("iot_services.security_desc"),
    },
  ];
  return (
    <div className="iot-services-sec">
      <Animatedheading
        outer={t("iot_services.innovations")}
        innerfirst={t("iot_services.internet_of")}
        innersecond={t("iot_services.things")}
      />
      <div className="iot-services">
        <div className="services-content">
          {serviceCard.map((item, index) => (
            <div
              className="service-card"
              key={index}
              onClick={() => navigate("/contact-us")}
              data-content={t("quote")}
            >
              <div className="icons">
                  <img
                    src={iconsBG}
                    alt="iconBG"
                    className="bgIcon"
                    
                  />
                  <img
                    src={item.icons}
                    alt="icon"
                    className="icon"
                  />
              </div>

              <h6>
                <span> {item.heading_t}</span>
                <span className=" d-block ">{item.heading_b}</span>
              </h6>
              <p className="desc">{ item.desc }</p>
              {/* <div className="quote">get a quote</div> */}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default IotServices;
