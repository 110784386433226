import React from "react";
import Banner from "../components/shared/banner";
import About from "../components/quantum/about/about.js";
import SecureSolutions from "../components/shared/secureSolutions/secureSolutions";
import Services from "../components/quantumservices/Services";
import Partner from "../components/quantum/partner/Partner.jsx";
import { useTranslation } from "react-i18next";

const Quantum = () => {
  const {t} = useTranslation();


  return (
    <>
      <Banner
        head= {t("quantum_banner.primary_heading")}
        subHead2={t("quantum_banner.secondary_heading")}
        textAlign="text-center"
        desktopvid="https://qazibucket-dest.s3.us-east-2.amazonaws.com/octaloop/aboutus.mp4"
        mobvid="https://qazibucket-dest.s3.us-east-2.amazonaws.com/octaloop/aboutus.mp4"
        poster="/assets/images/quantum_poster.webp"
      />
      <About />

      <SecureSolutions
        outer= {t("quantum_secure_solution.outer")}
        innerfirst={t("quantum_secure_solution.innerfirst")}
        innersecond={t("quantum_secure_solution.outer")}
        para={t("quantum_secure_solution.outer")}
      />
      <Services/>
      <Partner />


    </>
  );
};

export default Quantum;
