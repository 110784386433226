import React from "react";
import "./services.scss";

import { useTranslation } from "react-i18next";
import Animatedheading from "../animatedheading2/animatedheading2";
import QuantumHeading from "../quantum/quantumHeading";

const Services = () => {
  const { t } = useTranslation();
  const services = [
    {
      headingA: t("quantumservices.heading1A"),
      headingB: t("quantumservices.heading1B"),
      desc: t("quantumservices.para1"),
    },
    {
      headingA: t("quantumservices.heading2A"),
      headingB: t("quantumservices.heading2B"),
      desc: t("quantumservices.para2"),
    },
    {
      headingA: t("quantumservices.heading3A"),
      headingB: t("quantumservices.heading3B"),
      desc: t("quantumservices.para3"),
    },
    {
      headingA: t("quantumservices.heading4A"),
      headingB: t("quantumservices.heading4B"),
      desc: t("quantumservices.para4"),
    },
  ];

  const serviceCards = [
    {
      card1Head: t("servicesCards.card1headingA"),
      card1Desc: t("servicesCards.card1descA"),
      card2Head: t("servicesCards.card1headingB"),
      card2Desc: t("servicesCards.card1descB"),
      card3Head: t("servicesCards.card1headingC"),
      card3Desc: t("servicesCards.card1descC"),
    },
    {
      card1Head: t("servicesCards.card2headingA"),
      card1Desc: t("servicesCards.card2descA"),
      card2Head: t("servicesCards.card2headingB"),
      card2Desc: t("servicesCards.card2descB"),
      card3Head: t("servicesCards.card2headingC"),
      card3Desc: t("servicesCards.card2descC"),
      card4Head: t("servicesCards.card2headingD"),
      card4Desc: t("servicesCards.card2descD"),
    },
  ];

  return (
    <div className="q-sevices  ">
      <QuantumHeading
        outer ={t("quantum_service_heading.outer")}
        inner = {t("quantum_service_heading.inner")}
        para = {t("quantum_service_heading.para")}
      />

      <div className="inner ">
        <div className="service1">
          <div className="left  ">
            <div className="heading-sec">
              <Animatedheading
                innerfirst={services[0].headingA}
                innersecond={services[0].headingB}
              />
            </div>
            <div className="desc ">
              <img
                src="./assets/images/quantum/01.png"
                className="step-img "
                alt="step1"
              />
              <p> {services[0].desc} </p>
            </div>
          </div>
          <div className="right">
            <div className="img-outer">
              <img src="./assets/images/quantum_services1.png" className="inner-img " />
              <img
                src="./assets/images/feverback.webp"
                className="fever-back"
              />
            </div>
          </div>
        </div>

        <div className="service2">
          <div className="head-sec">
            <img className="service2-img" src="/assets/images/quantum/02.png" alt="img2" />
            <Animatedheading
              innerfirst={services[1].headingA}
              innersecond={services[1].headingB}
            />
            <div className="para">{services[1].desc}</div>
          </div>
          <div className="cards">
            <div className="service2-cards ">
              <div className="card1 ">
                <div className="card-img">
                  <img
                    src="/assets/images/quantumCards/card1.png"
                    alt="card1-img"
                  />
                </div>
                <div className="card-desc">
                  <p>
                    <p className="card-head">
                       {" "}
                      {serviceCards[0].card1Head}
                    </p>
                    {serviceCards[0].card1Desc}
                  </p>
                </div>
              </div>
              <div className="card1">
                <div className="card-img">
                  <img
                    src="/assets/images/quantumCards/card2.png"
                    alt="card1-img"
                  />
                </div>
                <div className="card-desc">
                  <p>
                    <p className="card-head">
                      {" "}
                      {serviceCards[0].card2Head}
                    </p>
                    {serviceCards[0].card2Desc}
                  </p>
                </div>
              </div>
              <div className="card1">
                <div className="card-img">
                  <img
                    src="/assets/images/quantumCards/card3.png"
                    alt="card1-img"
                  />
                </div>
                <div className="card-desc">
                  <p>
                    <p className="card-head">
                      {" "}
                      {serviceCards[0].card3Head}
                    </p>
                    {serviceCards[0].card3Desc}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="service3">
          <div className="head-sec">
            <img  src="/assets/images/quantum/03.png" alt="img2" className=" service2-img"/>
            <Animatedheading
              innerfirst={services[2].headingA}
              innersecond={services[2].headingB}
            />
            <div className="para">{services[1].desc}</div>
          </div>
          <div className="cards">
            <div className="service2-cards">
              <div className="card1">
                <div className="card-img">
                  <img
                    src="/assets/images/quantumCards/card4.png"
                    alt="card1-img"
                  />
                </div>
                <div className="card-desc">
                  <p>
                    <p className="card-head">
                      {" "}
                      {serviceCards[1].card1Head}
                    </p>
                    {serviceCards[1].card1Desc}
                  </p>
                </div>
              </div>
              <div className="card1">
                <div className="card-img">
                  <img
                    src="/assets/images/quantumCards/card5.png"
                    alt="card1-img"
                  />
                </div>
                <div className="card-desc">
                  <p>
                    <p className="card-head">
                      {" "}
                      {serviceCards[1].card2Head}
                    </p>
                    {serviceCards[1].card2Desc}
                  </p>
                </div>
              </div>
              <div className="card1">
                <div className="card-img">
                  <img
                    src="/assets/images/quantumCards/card6.png"
                    alt="card1-img"
                  />
                </div>
                <div className="card-desc">
                  <p>
                    <p className="card-head ">
                      {" "}
                      {serviceCards[1].card3Head}
                    </p>
                    {serviceCards[1].card3Desc}
                  </p>
                </div>
              </div>
              <div className="card1">
                <div className="card-img">
                  <img
                    src="/assets/images/quantumCards/card7.png"
                    alt="card1-img"
                  />
                </div>
                <div className="card-desc">
                  <p>
                    <p className="card-head ">
                      {" "}
                      {serviceCards[1].card4Head}
                    </p>
                    {serviceCards[1].card4Desc}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="service4">
          <div className="left  ">
            <div className="heading-sec">
              <Animatedheading
                innerfirst={services[3].headingA}
                innersecond={services[3].headingB}
              />
            </div>
            <div className="desc ">
              <img
                src="./assets/images/quantum/04.png"
                className="step-img "
                alt="step1"
              />
              <p> {services[3].desc} </p>
            </div>
          </div>
          <div className="right">
            <div className="img-outer">
              <img src="./assets/images/quantum/service4.png" className=" " />
              <img
                src="./assets/images/feverback.webp"
                className="fever-back"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Services;
