// import React from "react";
import Banner from "../components/shared/banner";
// import WorkFlow from "../components/home/workFlow";
// import About from "../components/home/about";
// import Footer from "../components/footer/footer";
import Faqs from "../components/faqs/faqs";
import Blogs from "../components/blogs/blogs";
import Testimonials from "../components/testimonials/testimonials";
import Letsconnect from "../components/letsconnect/letsconnect";
// import Teammembercard from "../components/teammembercard/teammembercard";
import Homebod from "../components/homebod/homebod";
import Quote from "../components/quote/quote";
import FeatureWork from "../components/aiprojects/aiprojects";
import Fever from "../components/fever/fever";
import Careeroppertunity from "../components/careeroppertunity/careeroppertunity";
import Helpinghands from "../components/helpinghands/helpinghands";
import DecipheringCards from "../components/shared/decipheringCards/decipheringCards";
import Services from "../components/shared/services/services";
import ServiceCards from "../components/shared/serviceCards/serviceCards";

import Join from "../components/join/join";
import Maestro from "../components/maestro/maestro";
// import Bridges from "../components/bridges/bridges";
import HappyClient from "../components/happyClient/happyClient";
import ScrollToTop from "../components/scroll/scroll";
import { useTranslation } from "react-i18next";
import SecureSolutions from "../components/shared/secureSolutions/secureSolutions";
import IotServices from "../components/iot/IotServices";

const Home = () => {
  const { t } = useTranslation();

  ScrollToTop();
  return (
    <div>
      <Banner
        head={t("banner.head")}
        textAlign="text-end"
        subHead={t("banner.subHead")}
        desktopvid="https://qazibucket-dest.s3.us-east-2.amazonaws.com/octaloop/hero.mp4"
        mobvid="https://qazibucket-dest.s3.us-east-2.amazonaws.com/octaloop/hero.mp4"
        poster="/assets/images/banner1.webp"
      />
      <Maestro />
      <HappyClient />
      <SecureSolutions
        outer={t("secure.quantum")}
        innerfirst={t("secure.secure")}
        innersecond={t("secure.solutions")}
        para={t("secure.intro_text")}
      />
      <DecipheringCards />
      <Services />
      <IotServices/>
      <FeatureWork />
      <Fever />
      <ServiceCards />
      <Join />
      <Helpinghands />
      <Quote />
      <Homebod />
      <Letsconnect />
      <Testimonials />
      <Blogs />
      <Careeroppertunity />
      <Faqs />
    </div>
  );
};

export default Home;
